
*{
  -ms-overflow-style: none;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 0;
  padding: 0px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #0d1117;
}

.topBar {
  display: flex;
  position: fixed;
  width: 100%;
  /*background-color: #0d1117;*/
  background: #0d1117;
  background: linear-gradient(0deg, rgb(2, 2, 17) 0%, rgba(11,19,42,1) 50%, rgb(17, 30, 49) 100%); 
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #101a29;
  z-index: 1;
}

.topBarContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.diskSpace {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.progressBar {
  width: 130px;
}

.logout {
  display: flex;
}

.App-header2{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}

.main {
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #0d1117;
  color: '#ffffff';
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.App-login{
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0d1117;
}

.login{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #2f343d;
  width: 18%;
  padding: 18px 31px 8px 29px;
  background-color: #161b22;
  border-radius: 6px;
  height: 180px;
  margin-bottom: 170px;
}

.loginForm {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.loginForm input{
  display: block;
  color: rgb(61, 61, 61);
  padding: 5px;
  border-radius: 6px;
  border: transparent;
}

.form-submit {
  background-color: green;
  border: transparent;
  border-radius: 6px;
  width: 100%;
  height: 30px;
  color: '#ffffff';
  cursor: pointer;
}

.message {
  color: red;
  font-size: 14px;
}

.blank {
  height: 5px;
}

.logOutButton {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  height: 25px;
}

.foldersCard {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  background-color: #161b22;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #2f343d;
  width: 500px;
}

.foldersView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin-top: 100px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
}

.singleFolder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%
}

.folderNameSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation {
  position: absolute;
  width: 100%;
  top: 60px;
}

.arrowBack {
  padding: 10px;
}

.foldersHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  color: '#ffffff';
}

.foldersContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  height: 100%;
  width: 100%;
  margin: 0;
}

.folder {
  width: 100%
}

.singleFolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
}

.folderName {
  padding-left: 20px;
  text-decoration: none;
  color: rgb(201, 201, 201)
}

.folderName:hover {
  color: greenyellow
}

.folderImage {
  width: 30px;
  height: 30px;
}

.divider {
  border: 1px solid rgb(44, 44, 44);
}

.loader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emptyFolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadFileForm {
  width: 300px;
  color: white;
}

.fileRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(44, 44, 44);
  width: 550px;
}

.fileNameRow {
  width: 100px
}

.fileRow-playing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(44, 44, 44);
  width: 500px;
  background-color: #20252b;
}

.allFilesView {
  margin-top: 40px;
  height: 100%;
}

.filesView {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 100px;
  overflow: scroll;
  overflow-x: hidden;
  height: 530px;
  padding: 12px;
}

.fileActionButtons {
  display: flex;
  flex-direction: row;
  width: 90px;
  justify-content: space-evenly;
  align-items: center;
}

#file-date {
  color: rgb(122, 122, 122);
  width: 55px;
  font-size: 12px;
  text-align: left;
}

#file-name {
  color: white;
  width: 130px;
  font-size: 11px;
  overflow: hidden;
}

#file-name-playing {
  color: rgb(122, 255, 182);
  width: 130px;
  font-size: 12px;
  overflow: hidden;
}

.deleteButton {
  cursor: pointer;
  padding: 5px;
  width: 20px;
}

.downloadButton {
  cursor: pointer;
  width: 25px;
  padding: 5px;
}

.playButtons {
  width: 50px;
}

.deleteButtonButton {
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
  margin-left: 5px;
  margin-right: 7px;
  border: none;
  background: transparent;
  border-radius: 3px;
  height: 30px;
  width: 40px;
  color: #92abcf;

}

.deleteButtonButton:hover {
  color: rgb(255, 126, 126);
}

.deleteButton:hover{
  color: rgb(255, 126, 126);
}

.downloadButton:hover {
  color: greenyellow;
}

.bottomPlayer {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  align-items: center;
  width: 100%;
  height: 76px;
  background: #0d1117;
  background: linear-gradient(0deg, rgb(2, 2, 17) 0%, rgba(11,19,42,1) 50%, rgb(17, 30, 49) 100%);
}

.playerControls {
  width: 100%;
  margin: 0 30px 0 10px;
}

.chatRoom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.chatLog {
  margin-top: 250px;
  display: flex;
}

.chatForm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat__sidebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-color: #101a29;
  z-index: 2;
}

.chat__users {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  width: 80%;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 15px;
}
.sendBtn {
  width: 150px;
  background-color: green;
  padding: 10px;
  border: none;
  outline: none;
  color: #eae3d2;
  cursor: pointer;
  margin-left: 5px;
}

.message__container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 110px;
}

.message__chats {
  max-height: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;
}

.message__recipient {
  display: flex;
  flex-direction: row;
  width: 95%;
  padding: 3px;
  font-size: 13px;
}
.message__sender {
  display: flex;
  flex-direction: row;
  max-width: 95%;
  padding: 3px;
  font-size: 13px;
}

.sender__name {
  color: white;
  width: 60px;
}

.sender__name__offline {
  color: rgb(78, 78, 78);
  width: 60px;
}

.message_recipient_text {
  color: rgb(113, 139, 255)
}

.message_sender_text {
  color: greenyellow
}

.message__status {
  margin-left: 40px;
}

.leaveChat__btn {
  width: 100px;
  height: 40px;
  background-color: rgb(252, 81, 118);
  padding: 10px;
  border: none;
  outline: none;
  color: #eae3d2;
  cursor: pointer;
}

@media(max-width: 920px){
  .login{
    width: 90%;
    padding: 0;
  }

  .foldersCard {
    width: 300px;
  }

  .fileRow {
    max-width: 90vw;
  }
}
